<template>
  <div>
    <h3>Myforexglobal Academy</h3>
    <div class="card">
      <div class="card-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>